<template>
  <div id="app" class="container">
    <Header class="header" v-if="headerVisible" />
    <div class="main">
      <router-view></router-view>
    </div>
    <Footer class="footer" v-if="headerVisible" />
  </div>
</template>

<script>
import Header from "./components/common/Header.vue";
import Footer from "./components/common/Footer.vue";

export default {
  name: "App",
  components: { Header, Footer },
  data() {
    return {
      routeKsy: "",
      headerVisible: false,
    };
  },
  mounted() {
    this.checkPlatform();
  },
  methods: {
    // 下面这段代码，仅在处理多端时使用
    checkPlatform() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
      var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
      var bIsMidp = sUserAgent.match(/midp/i) == "midp";
      var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
      var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
      var bIsAndroid = sUserAgent.match(/android/i) == "android";
      var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
      var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
      // alert("您的浏览设备为：");
      if (
        bIsIpad ||
        bIsIphoneOs ||
        bIsMidp ||
        bIsUc7 ||
        bIsUc ||
        bIsAndroid ||
        bIsCE ||
        bIsWM
      ) {
        // document.writeln("phone");
        // alert("您的浏览设备为：phone");
        this.$router.push("H5Page");
      } else {
        // document.writeln("pc");
        // alert("您的浏览设备为：pc");
        this.$router.push("WebPage");
        // this.$router.push({ path: "account", replace: true });
      }
    },
  },
  watch: {
    $route: function (to) {
      // console.log(to);
      if (to) {
        this.routeKsy = to.name;
        sessionStorage.setItem("routeKsy", to.name);
      }

      const path = ["Login", "WebPage", "H5Page"];
      this.headerVisible = !path.includes(to.name);
    },
  },
};
</script>

<style lang="scss">
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .header {
    flex: none;
  }
  .footer {
    flex: none;
  }
  .main {
    flex: 1;
  }
}
</style>
