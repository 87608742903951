import Vue from 'vue';
import VueRouter from 'vue-router';
import App from '../App.vue';
import Login from '../views/Login.vue';

Vue.use(VueRouter);

const routes = [
  { path: '/', redirect: { name: 'Home' } },
  { path: '/Home', name: 'Home', component: App },
  { path: '/login', name: 'Login', component: Login },
  {
    path: '/WebPage',
    name: 'WebPage',
    component: () => import('../components/WebPage.vue')
  },
  {
    path: '/H5Page',
    name: 'H5Page',
    component: () => import('../components/H5Page.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/announce',
    name: 'Announce',
    component: () => import('../views/Announce.vue')
  },
];

const router = new VueRouter({
  routes
});

// route guard
// router.beforeEach((to) => {
//   const token = localStorage.getItem('token');
//   console.log(to.path);
//   if (!token && to.path != '/login') {
//     return '/login';
//   } else {
//     return true;
//   }
// });

export default router;
