import Vue from 'vue';
import App from './App.vue';
import router from './router';
// import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import '../theme/index.css';

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import store from './store';
import './common/font/font.css';
import './assets/css/style.scss';


Vue.config.productionTip = false;
Vue.use(Antd);
Vue.use(ElementUI, { locale });
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
