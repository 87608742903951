import $http from './index';

export const sendLoginCode = data => $http({ url: '/login/sendLoginCode', method: 'post', data });

export const loginPhone = data => $http({ url: '/login/loginPhone', method: 'post', data });

export const loginOut = () => $http({ url: '/login/loginOut', method: 'get' });

export const userInfo = () => $http({ url: '/user', method: 'get' });

export const userInfoUpdate = data => $http({ url: '/user/update', method: 'post', data });

export const productList = data => $http({ url: '/product/list', method: 'post', data });

export const userProductInfo = data => $http({ url: '/product/userProductInfo', method: 'post', data });

export const orderList = data => $http({ url: '/order/list', method: 'post', data });

export const userOrderInfo = data => $http({ url: '/order/info', method: 'post', data });

export const redemingFunds = data => $http({ url: '/userProduct/redemingFunds', method: 'post', data });
