import axios from 'axios';
import { Message } from 'element-ui';
import router from '../router';

// encapsulate http via aixos library
const $http = axios.create({
  baseURL: 'https://api.arxhe.com.au/api',
  // headers: {},
});

// interceptor
$http.interceptors.request.use(config => {
  config.headers = config.headers || {};
  if (localStorage.getItem('token')) {
    config.headers.token = localStorage.getItem('token') || '';
  }
  return config;
});

$http.interceptors.response.use(res => {
  const code = res.data.code;
  console.log(router.currentRoute);

  // filter home pages
  const homeRouters = ["WebPage", "H5Page"];
  const currentRoute = router.currentRoute.name;
  if (homeRouters.includes(currentRoute)) {
    if (code !== 0) {
      router.push("/login");
    }
    else {
      router.push("/account");
    }
    return res.data;
  }

  // error message
  if (code !== 0) {
    Message.error(res.data.message);
    return Promise.reject(res.data);
  }
  return res.data;
}, err => {
  Message.error(err);
  // console.log(err);
  return Promise.reject(err);
});

export default $http;
