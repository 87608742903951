<template>
  <div class="login-box">
    <div class="logo-bar">
      <img src="images/logo.svg" style="width: 150px" />
    </div>
    <div class="login-form">
      <div class="item icon">
        <img src="images/login.svg" style="width: 100px" />
      </div>
      <div class="item title">Sign in</div>
      <!-- <div class="item subtitle">New to GoDaddy? Create an account</div> -->
      <div class="item">
        <el-input
          class="form-control"
          v-model="username"
          placeholder="Phone *"
          size="mini"
        >
          <template slot="prepend">+61</template>
        </el-input>
      </div>
      <div class="item">
        <el-input
          class="form-control"
          v-model="password"
          placeholder="Code *"
          size="mini"
          show-password
        >
          <el-button
            slot="append"
            @click="sms"
            v-bind:disabled="click_send_sms"
            style="width: 90px"
          >
            {{ send_sms_tips }}
          </el-button>
        </el-input>
      </div>
      <!-- <div class="item">
        <el-checkbox v-model="checked">
          Keep me sign in on this device
        </el-checkbox>
      </div> -->
      <div class="item">
        <el-button type="primary" class="form-control" @click="login">
          Sign in
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { sendLoginCode, loginPhone, userInfo } from "../http/api";

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      checked: false,
      send_sms_tips: "Get a code",
      click_send_sms: false,
    };
  },
  methods: {
    sms() {
      const params = {
        phone: this.username,
      };
      this.click_send_sms = true;
      this.send_sms_tips = 60;

      var times = setInterval(() => {
        this.send_sms_tips--;
        if (this.send_sms_tips <= 0) {
          this.send_sms_tips = "Get a code";
          this.click_send_sms = false;
          clearInterval(times);
        }
      }, 1000);

      sendLoginCode(params).then(() => {});
    },
    login() {
      const params = {
        phone: this.username,
        code: this.password,
      };

      loginPhone(params).then((res) => {
        localStorage.setItem("token", res.data);
        this.$router.push("/account");

        userInfo().then((res) => {
          const user = res.data;
          localStorage.setItem("user", JSON.stringify(user));
        });
      });
    },
  },
};
</script>

<style lang="scss">
$formWidth: 300px;

.login-box {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.logo-bar {
  margin-left: 10%;
  margin-top: 50px;
}

.login-form {
  width: $formWidth;
  margin: 150px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .item {
    margin: 10px 0px;
  }

  .icon {
  }

  .title {
    color: var(--primary-color);
    font-size: 25px;
  }

  .subtitle {
    align-self: center;
    font-size: 16px;
  }

  .form-control {
    width: $formWidth;
  }

  // no-effect
  .el-button--default span {
    font-size: 5px !important;
  }
}
</style>