<template>
  <div class="footer">
    <div class="bottom-icon-wrap hidden-xs-only">
      <div class="icon">
        <img src="../../assets/img/icon3.svg" />
        <img src="../../assets/img/icon4.svg" />
      </div>
      <div class="icon logo">
        <img src="../../assets/img/icon2.svg" />
      </div>
      <div class="icon">
        <img src="../../assets/img/icon5.svg" />
        <img src="../../assets/img/icon6.svg" />
      </div>
    </div>
    <div class="address">
      <div>Address: Level 5, 4-6 Gold St, Collingwood VIC 3006</div>
      <div>Tel: +61 3 9817 6888</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss">
.footer {
  .bottom-icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      img {
        width: 80px;
        height: 40px;
      }
    }

    .logo img {
      width: 230px;
      height: 80px;
    }
  }

  .address {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    font-family: "visiapro-bold";
    margin: 30px 0;
  }
}
</style>