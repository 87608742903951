import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    activeTab: 'product'
  },
  mutations: {
    updateTab(state, tab) {
      this.state.activeTab = tab;
    }
  },
  actions: {
  },
  modules: {
  }
});

export default store;
