<template>
  <div class="header">
    <div>
      <img src="images/logo-white.svg" class="logo" />
    </div>
    <el-menu
      :default-active="activeIndex"
      class="navbar"
      mode="horizontal"
      menu-trigger="click"
      @select="handleSelect($event)"
    >
      <!-- <el-submenu index="1">
        <template slot="title">
          <img src="images/grid.svg" class="icon" />
          <span class="hidden-xs-only">My Account</span>
        </template>
        <el-menu-item style="color: #003d62">My Products</el-menu-item>
        <el-menu-item index="/account">
          &nbsp;&nbsp;Account & Settings
        </el-menu-item>
        <el-menu-item style="color: #003d62">Quick Links</el-menu-item>
      </el-submenu> -->
      <el-submenu index="2" v-if="user">
        <template slot="title">
          <img src="images/bell.svg" class="icon" />
          <span class="hidden-xs-only">Hello, {{ user.name }}</span>
        </template>
        <el-menu-item style="color: #003d62">{{ user.name }}</el-menu-item>
        <el-menu-item>
          &nbsp;&nbsp;#Customer #: {{ user.customer_id }}
        </el-menu-item>
        <el-menu-item style="color: #003d62">Account</el-menu-item>
        <el-menu-item index="product">&nbsp;&nbsp;My Products</el-menu-item>
        <el-menu-item index="account"
          >&nbsp;&nbsp;Account Settings</el-menu-item
        >
        <!-- <el-menu-item index="/account/billing">&nbsp;&nbsp;Biling</el-menu-item> -->
        <el-menu-item index="logout" style="color: #003d62">
          Sign Out
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import { loginOut } from "../../http/api";

export default {
  name: "Header",
  data() {
    return {
      activeIndex: "2",
      user: {
        name: "",
        customer_id: "",
      },
    };
  },
  mounted() {
    const user = localStorage.getItem("user");
    this.user = JSON.parse(user);
    setInterval(() => {
      const user = localStorage.getItem("user");
      this.user = JSON.parse(user);
    }, 5000);
  },
  methods: {
    logout() {
      loginOut().then(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        this.$router.push("/login");
      });
    },
    handleSelect(index) {
      const path = ["product", "account"];
      if (path.includes(index)) {
        this.$store.commit("updateTab", index);
        this.$router.push('/account');
      } else if (index === "logout") {
        this.logout();
      }
    },
  },
};
</script>

<style lang="scss">
.header {
  background-color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 calc((100vw - var(--panel-width)) / 2);
  height: 61px;

  .logo {
    width: 150px;
  }
  .navbar {
    background-color: var(--primary-color);
    border-bottom: 1px solid var(--primary-color) !important;

    .icon {
      width: 18px;
      margin-right: 10px;
    }
    .el-submenu:focus .el-submenu__title,
    .el-submenu:hover .el-submenu__title {
      color: #fff;
      background-color: var(--primary-color);
    }
    .el-submenu__title {
      color: #fff !important;
    }
    .el-submenu__title:hover {
      color: #fff;
      background-color: var(--primary-color);
    }
  }
}
</style>